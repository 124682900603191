// import logo from '../public/img/comming_soon.png';
import './App.css';

function App() {
  return (
      <img src="img/comming_soon.png" alt="logo" style={{ width: '100%',height:"100vh" }} /> 

  );
}

export default App;
